<template>
  <div class="container">

    <div class="my-4">
      <h4 class="text-center mb-4">Выпускная работа</h4>

      <div class="col-md-12 my-4"
           v-if="Object.keys(uploadGraduateWork_form.studentGraduateWorkThemeInfo).length">

        <div class="card my-2">
          <div class="card-body">
            <div class="card-title mb-4">
              <h5>Информация о дипломной работе</h5>
            </div>
            <div>
              <p>
                <b>Название темы (на казахском):</b>
                {{ uploadGraduateWork_form.studentGraduateWorkThemeInfo?.theme_name_kz }}
              </p>
              <p>
                <b>Название темы (на русском):</b>
                {{ uploadGraduateWork_form.studentGraduateWorkThemeInfo?.theme_name_ru }}
              </p>
              <p>
                <b>Название темы (на английском):</b>
                {{ uploadGraduateWork_form.studentGraduateWorkThemeInfo?.theme_name_en }}
              </p>
              <p class="mb-0">
                <b>Руководитель:</b> {{ uploadGraduateWork_form.studentGraduateWorkThemeInfo?.pps_data_lastname }}
                {{ uploadGraduateWork_form.studentGraduateWorkThemeInfo?.pps_data_firstname }}
              </p>
            </div>
          </div>
        </div>


        <div
            v-for="(item,index) in uploadGraduateWork_form.graduateWorkChapterInfo"
            :key="index">
          <!-- Убрали ограничения по времени 08.02.2022. Добавить filter(i => i.to_date > dateNow() && i.from_date < dateNow())
                  чтобы вернуть обратно
          -->


          <Card class="my-4">
            <template #title>
              <h5>{{ item.chapter_name }}</h5>
              <vue-countdown :time="getCountdown(item.to_date)" v-slot="{ days, hours, minutes, seconds }"
                             v-if="item.graduate_work_chapter_id != '4'">
                Осталось：{{ days }} дней, {{ hours }} часов, {{ minutes }} минут, {{ seconds }} секунд.
              </vue-countdown>
            </template>

            <template #content>


              <!--                <div v-for="(filesItem, index) in uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.filter(i=>i.graduate_work_chapter_id == item.id)" :key="index">-->
              <div class="mt-4"
                   v-if="uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.filter(i => i.graduate_work_chapter_id == item.id).length != 0">

                <div class="mt-2 mb-4" v-if="item.graduate_work_chapter_id === '4' && (uploadGraduateWork_form.checkAccess.count < 3 || uploadGraduateWork_form.checkAccess.status == 1)">
                  <Button type="button" icon="pi pi-plus" label="Добавить новый файл" @click="openAddFinalChapterFileDialog(getFinalChapterGraduateWorkStudent)"/>
                </div>
                <div
                    v-for="(filesItem, index) in uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.filter(i=>i.graduate_work_chapter_id == item.id && i.parent_id == null)"
                    :key="index">

                  <!-- main comment section -->



                  <div class="px-1">
                    <div class="fw-bolder" style="color: #3b8afd">
                      {{filesItem.lastname}} {{filesItem.firstname}}
                    </div>
                    <div class="mt-2">
                      <p class="fs-5">{{filesItem.comment_text}}</p>
                      <p v-if="filesItem.file_url != null">
                        <a :href="'https://back.uib.kz/uploads/' + filesItem.file_url" class="mx-1">
                          Файл
                        </a>
                      </p>
                    </div>
                    <div class="mt-2 fs-6">
                      {{convertGraduateWorkTimestampToDate(filesItem.comment_created_at)}}
                    </div>

                    <div class="mt-2" v-if="item.graduate_work_chapter_id === '4'">



                        <div v-if=" filesItem.status != 2">
                          <div>
<!--                            <Button icon="pi pi-send" label="Отправить на плагиат" @click="submitFinalFileToPlagiat(filesItem.id)"/>-->

                            <button type="button" class="btn btn-primary m-1 mb-4" @click="submitFinalFileToPlagiat(filesItem.id)"
                                    :disabled="btnSaveStatus">
                              Отправить на плагиат
<!--                              <span v-if="btnSaveStatus" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>-->
<!--                              {{ btnSaveStatus ? 'Отправляется' : 'Отправить на плагиат' }}-->
                            </button>



                          </div>
                        </div>
                      <div v-else>
                        <div v-if="uploadGraduateWork_form.diplomaResults.find(i=>i.graduate_work_comment_id == filesItem.id)?.plagiarism_result?.status == '6'
                                   && uploadGraduateWork_form.diplomaResults.find(i=>i.graduate_work_comment_id == filesItem.id)?.report_ready == '1'">
                          <b>Процент плагиата: {{Math.round(parseFloat(uploadGraduateWork_form.diplomaResults.find(i=>i.graduate_work_comment_id == filesItem.id)?.plagiarism_result?.result))}}%</b>
                          <br>
                          <b>Процент уникальности: {{Math.round(parseFloat(uploadGraduateWork_form.diplomaResults.find(i=>i.graduate_work_comment_id == filesItem.id)?.percentage_of_uniqueness))}}%</b>
                          <br>
                          <a class="btn btn-primary" role="button" target="_blank"
                             :href="'https://plagiarism.uib.kz/strikeplagiarism/get_diploma_report.php?file_id='+uploadGraduateWork_form.diplomaResults
                             .find(i=>i.graduate_work_comment_id == filesItem.id)?.plagiarism_result?.diploma_file_id">Посмотреть отчет</a>


                        </div>
                        <div v-else>
                            <b>Результата еще нет</b>

                        </div>
                      </div>

                    </div>



                    <div class="d-flex flex-row-reverse">
                      <div v-if="item.graduate_work_chapter_id !== '4'" >
<!--                        && getUserId === chapterAccess.find(i=>i.user_id === getUserId)?.user_id-->
                        <Button class="p-button-secondary" icon="pi pi-comment" label="Комментировать"
                                @click="openCommentSection(filesItem.id)" :disabled="(item.to_date) - dateNow() < 0"/>

                      </div>


                      <!--                        v-bind:disabled="uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.filter(i=>i.graduate_work_chapter_id == 1)"-->
                      <!--                        <button type="button" class="btn btn-secondary" @click="openCommentSection(filesItem.id)">-->
                      <!--                          <i class="fa fa-comment-o" aria-hidden="true"></i>&nbsp;Комментировать-->
                      <!--                        </button>-->
                    </div>
                  </div>


                  <!--                  <div class="mb-2">-->
                  <!--                    <span class="badge text-dark">{{convertGraduateWorkTimestampToDate(filesItem.created_at)}}</span>-->
                  <!--                  </div>-->
                  <!--                  <div class="row mb-3">-->
                  <!--                    <div class="col-md-6">-->
                  <!--                      <Button type="button"-->
                  <!--                              :label="filesItem.student_lastname + ' ' + filesItem.student_firstname + ' '+ filesItem.student_barcode"-->
                  <!--                              icon="pi pi-user" class="p-button-warning p-mr-2"/>-->
                  <!--                    </div>-->

                  <!--                  </div>-->
                  <!--                  <div class="row">-->

                  <!--                    <div class="col-md-12">-->
                  <!--                      <p style="font-size: 26px"> <b>{{ filesItem.comment_text }}</b></p>-->
                  <!--                    </div>-->
                  <!--                    <div class="col-md-12" v-if="filesItem.file_url != null">-->
                  <!--                        <a :href="'https://back.uib.kz/uploads/' + filesItem.file_url"><i class="far fa-file-word fa-2x"></i></a>-->
                  <!--                    </div>-->

                  <!--                    <hr>-->
                  <!--                    <div class="d-flex flex-row-reverse">-->
                  <!--                      <Button class="p-button-secondary" icon="pi pi-comment" label="Комментировать"-->
                  <!--                              @click="openCommentSection(filesItem.id)"/>-->
                  <!--                    </div>-->

                  <!--                  </div>-->


                  <div class="comment-section my-4" v-if="isCommentSectionVisible">
                    <div class="row">

                      <div class="col-md-6">
                        <input type="file" class="form-control"
                               @change="commentHandleFileUpload(item.id, filesItem.graduate_work_student, $event)">
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-6">
                        <textarea id="comment" class="form-control mb-2" rows="5"
                                  @change="changeGraduateWorkChapterComment(item.id, filesItem.graduate_work_student, $event)"
                                  placeholder="Оставьте свой комментарий"></textarea>

                        <div class="d-flex flex-row-reverse">
                          <Button icon="pi pi-send" label="Отправить"
                                  @click="submitGraduateWorkChapterFile()" :disabled="(item.to_date) - dateNow() < 0"/>
                        </div>

                      </div>
                    </div>

                  </div>
                  <hr>


                  <div class="mt-2">

                    <div class="col-md-12 child-comment-section"
                         v-for="(childCommentItem, index) in uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.filter(i=>i.parent_id == filesItem.id)"
                         :key="index">

                      <div class="card mt-2" :class="[childCommentItem.user_id!=getUserId?'':'bg-light']">
                        <div class="card-body">
                          <div v-if="childCommentItem.parent_id != null">
                            <div class="fw-bolder"
                                 :style="{ color: childCommentItem.user_id!=getUserId?'#3b8afd':'#fd9741' }">
                              {{childCommentItem.lastname}} {{childCommentItem.firstname}}
                            </div>
                            <div class="mt-2">
                              <p class="fs-5">{{childCommentItem.comment_text}}</p>
                              <p v-if="childCommentItem.file_url != null">
                                <a :href="'https://back.uib.kz/uploads/' + childCommentItem.file_url" class="mx-1">
                                  Файл
                                </a>
                              </p>
                            </div>
                            <div class="mt-2 fs-6">
                              {{convertGraduateWorkTimestampToDate(childCommentItem.comment_created_at)}}
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>


                </div>
              </div>

              <div v-else>
                <div class="card mt-4">
                  <div class="card-body">

                    <div v-if="item.graduate_work_chapter_id !== '4'">

                      <div>
<!--                        v-if="getUserId === chapterAccess.find(i=>i.user_id === getUserId)?.user_id"-->
                        <div class="row mb-4">
                          <div class="col-md-6">
                            <input type="file" class="form-control" @change="handleFileUpload(item.id, $event)">
                          </div>
                        </div>

                        <div class="row mb-4">
                          <div class="col-md-6">
                        <textarea class="form-control mb-2" rows="5"
                                  @change="changeNewGraduateWorkChapterComment(item.id, $event)"
                                  placeholder="Оставьте свой комментарий"></textarea>

                            <div class="d-flex flex-row-reverse">
                              <Button icon="pi pi-send" label="Отправить"
                                      @click="submitGraduateWorkChapterFile()"/>
                            </div>

                          </div>
                        </div>
                      </div>



                    </div>
                    <!-- Начало загрузки финальной главый дипломной работы -->
                    <div v-else>

                      <div class="row mb-4">
                        <div class="col-md-6">
                          <input type="file" class="form-control"
                                 @change="handleFileUploadFinalChapter(item.id, $event)">
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="col-md-6">
                          <!--                        <textarea class="form-control mb-2" rows="5"-->
                          <!--                                  @change="changeGraduateWorkFinalChapterComment(item.id, $event)"-->
                          <!--                                  placeholder="Оставьте свой комментарий"></textarea>-->

                          <div class="d-flex flex-row-reverse">
                            <Button icon="pi pi-send" label="Отправить"
                                    @click="submitGraduateWorkFinalChapter()"/>
                          </div>

                        </div>
                      </div>

                    </div>


                  </div>


                </div>
              </div>
              <!-- Конец загрузки финальной главый дипломной работы -->


            </template>


          </Card>


        </div>


        <Dialog v-model:visible="addFinalChapterFileDisplay" :style="{width: '50vw'}" :closable="false">
          <template #header>
            <h5>Добавить новый файл</h5>
          </template>

          <div class="col-md-12 mt-4">

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Файл</label>
              <div class="col-md-9">

                <input type="file" class="form-control"
                       @change="handleFileUploadFinalChapter(4, $event)">
              </div>
            </div>


          </div>

          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                    @click="closeAddFinalChapterFileDialog"/>
            <Button label="Отправить" icon="pi pi-check" @click="submitGraduateWorkFinalChapter"/>
          </template>
        </Dialog>


        <!--        <Dialog v-model:visible="graduateWorkChapterFileUpdateDisplay" :style="{width: '50vw'}">-->
        <!--          <template #header>-->
        <!--            <h5>Обновить файл</h5>-->
        <!--          </template>-->

        <!--          <div style="height: 400px" class="col-md-12 mt-4">-->
        <!--            <input type="file" class="form-control" id="update_file" @change="updateHandleFileUpload">-->

        <!--          </div>-->

        <!--          <template #footer>-->
        <!--            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"-->
        <!--                    @click="closeGraduateWorkChapterFileUpdateDialog"/>-->
        <!--            <Button label="Обновить" icon="pi pi-check" @click="putGraduateWorkChapterFile"/>-->
        <!--          </template>-->
        <!--        </Dialog>-->


        <!--        <Dialog v-model:visible="graduateWorkChapterFileAddDisplay" :style="{width: '50vw'}">-->
        <!--          <template #header>-->
        <!--            <h5>Добавить файл</h5>-->
        <!--          </template>-->

        <!--          <div style="height: 400px" class="col-md-12 mt-4">-->
        <!--            <input type="file" class="form-control" @change="handleFileUpload">-->

        <!--          </div>-->

        <!--          <template #footer>-->
        <!--            <Button label="Закрыть" icon="pi pi-times" class="p-button-text"-->
        <!--                    @click="closeGraduateWorkChapterFileAddDialog"/>-->
        <!--            <Button label="Добавить" icon="pi pi-check" @click="submitGraduateWorkChapterFile"/>-->
        <!--          </template>-->
        <!--        </Dialog>-->

      </div>

      <div v-else>
        <div class="text-center">
          Тема дипломной работы не выбрана
        </div>
      </div>


    </div>

  </div>


</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: "UploadGraduateWork",
    data() {
      return {
        // graduateWorkChapterFileUpdateDisplay: false,
        // graduateWorkChapterFileAddDisplay: false,
        isCommentSectionVisible: false,
        addFinalChapterFileDisplay: false,
        btnSaveStatus: false,
        // chapterAccess: [
        //   {
        //   user_id: '3601',
        //     //24.05
        //   //chapter_id: ['1']
        //   },
        //   {
        //     user_id: '3669',
        //     //24.05
        //     //chapter_id: ['1', '2', '3']
        //   },
        //   {
        //     user_id: '3375',
        //     //25.05
        //   },
        //   {
        //     user_id: '3750',
        //     //25.05
        //   },
        //   {
        //     user_id: '3793',
        //     //25.05
        //   },
        //   {
        //     user_id: '3474',
        //     //25.05
        //   },
        //   {
        //     user_id: '3575',
        //     //25.05
        //   },
        //   {
        //     user_id: '3486',
        //     //25.05
        //   },
        //   {
        //     user_id: '3807'
        //     //26.05
        //   },
        //   {
        //     user_id: '3176',
        //     //26.05
        //   },
        //   {
        //     user_id: '3451',
        //     //26.05
        //   },
        //   {
        //     user_id: '3372',
        //     //26.05
        //   },
        //   {
        //     user_id: '3545',
        //     //31.05
        //   },
        //   {
        //     user_id: '6573',
        //     //06.06
        //   }
        // ]
      }
    },
    computed: {
      ...mapState('uploadGraduateWork', ['uploadGraduateWork_form']),
      getUserId() {
        return getCookie('USER_ID')
      },
      getFinalChapterGraduateWorkStudent() {
        let finalChapter = this.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.find(i=>i.graduate_work_chapter_id == '4').graduate_work_student
        return finalChapter
        //return finalChapter.length > 0 ? finalChapter[0].graduate_work_student : null
      }
    },
    methods: {
      ...mapActions('uploadGraduateWork', ['GET_STUDENT_GRADUATE_WORK_THEME', 'GET_GRADUATE_WORK_CHAPTER', 'POST_GRADUATE_WORK_CHAPTER_FILE',
        'GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES', 'PUT_GRADUATE_WORK_CHAPTER_FILE', 'POST_FINAL_CHAPTER_FILE', 'POST_FINAL_FILE_TO_PLAGIAT', 'GET_CHECK_ACCESS', 'GET_DIPLOMA_RESULTS']),
      ...mapMutations('uploadGraduateWork', ['SET_GRADUATE_WORK_CHAPTER_FILE', 'SET_GRADUATE_WORK_CHAPTER_UPDATE_FILE',
        'SET_GRADUATE_WORK_CHAPTER_UPDATE_FILE_ID', 'SET_GRADUATE_WORK_CHAPTER_COMMENT', 'SET_PARENT_ID',
        'SET_GRADUATE_WORK_NEW_CHAPTER_COMMENT', 'CLEAR_GRADUATE_WORK_CHAPTER_FILES', 'SET_GRADUATE_WORK_NEW_CHAPTER_COMMENT_FILE',
        'CLEAR_STATE', 'SET_FINAL_CHAPTER_FILE', 'SET_FINAL_CHAPTER_COMMENT', 'CLEAR_STATE_FINAL_CHAPTER', 'SET_FINAL_CHAPTER_GRADUATE_WORK_STUDENT']),
      convertGraduateWorkTimestampToDate,
      getCountdown(unix_timestamp) {
        const deadline = new Date(unix_timestamp * 1000);
        const now = new Date();
        return deadline - now
      },
      openCommentSection(parent_id) {
        this.isCommentSectionVisible = this.isCommentSectionVisible !== true;

        this.CLEAR_GRADUATE_WORK_CHAPTER_FILES()

        this.SET_PARENT_ID(parent_id)
      },
      dateNow() {
        return Math.round((new Date()).getTime() / 1000);
      },
      // openGraduateWorkChapterFileUpdateDialog(graduate_work_student_id) {
      //   this.graduateWorkChapterFileUpdateDisplay = true
      //   console.log(graduate_work_student_id, 'graduate_work_student_id')
      //   this.SET_GRADUATE_WORK_CHAPTER_UPDATE_FILE_ID(graduate_work_student_id)
      // },
      // closeGraduateWorkChapterFileUpdateDialog() {
      //   this.graduateWorkChapterFileUpdateDisplay = false
      // },
      // openGraduateWorkChapterFileAddDialog(graduate_work_chapter_id) {
      //   this.graduateWorkChapterFileAddDisplay = true
      //   console.log(graduate_work_chapter_id, 'graduate_work_chapter_id')
      // },
      // closeGraduateWorkChapterFileAddDialog() {
      //   this.graduateWorkChapterFileAddDisplay = false
      // },

      // async putGraduateWorkChapterFile() {
      //   this.graduateWorkChapterFileUpdateDisplay = false
      //   await this.PUT_GRADUATE_WORK_CHAPTER_FILE()
      //   await this.GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES()
      // },
      changeGraduateWorkChapterComment(id, graduate_work_student_id, e) {
        const value = e.target.value
        let graduate_work_chapter_id = id
        let graduate_work_student = graduate_work_student_id
        console.log(value, "changeGraduateWorkChapterComment")
        this.SET_GRADUATE_WORK_CHAPTER_COMMENT({
          'comment': value,
          'graduate_work_chapter_id': graduate_work_chapter_id,
          'graduate_work_student': graduate_work_student
        })


      },

      changeNewGraduateWorkChapterComment(id, e) {
        const value = e.target.value
        let graduate_work_chapter_id = id

        console.log(value, "changeNewGraduateWorkChapterComment")
        this.SET_GRADUATE_WORK_NEW_CHAPTER_COMMENT({
          'comment': value,
          'graduate_work_chapter_id': graduate_work_chapter_id
        })

      },

      changeGraduateWorkFinalChapterComment(id, e) {
        const value = e.target.value
        let graduate_work_chapter_id = id

        console.log(value, "changeGraduateWorkFinalChapterComment")
        this.SET_FINAL_CHAPTER_COMMENT({
          'comment': value,
          'graduate_work_chapter_id': graduate_work_chapter_id
        })

      },
      handleFileUpload(id, e) {
        console.log(e.target, 'e target')
        console.log(id, 'id')
        let file = e.target.files[0];
        let graduate_work_chapter_id = id
        this.SET_GRADUATE_WORK_CHAPTER_FILE({'file': file, 'graduate_work_chapter_id': graduate_work_chapter_id})
        console.log({
          'file': file,
          'graduate_work_chapter_id': graduate_work_chapter_id,
        }, 'set graduate work chapter file')
      },
      handleFileUploadFinalChapter(id, e) {

        console.log(id, 'final chapter id')
        let final_chapter_file = e.target.files[0];
        let graduate_work_chapter_id = id
        this.SET_FINAL_CHAPTER_FILE({
          'final_chapter_file': final_chapter_file,
          'graduate_work_chapter_id': graduate_work_chapter_id
        })
        console.log({
          'final_chapter_file': final_chapter_file,
          'graduate_work_chapter_id': graduate_work_chapter_id,
        }, 'final chapter file')
      },

      commentHandleFileUpload(id, graduate_work_student_id, e) {
        console.log(e.target, 'e target')
        console.log(id, 'id')
        let file = e.target.files[0];
        let graduate_work_chapter_id = id
        let graduate_work_student = graduate_work_student_id
        this.SET_GRADUATE_WORK_NEW_CHAPTER_COMMENT_FILE({
          'file': file,
          'graduate_work_student': graduate_work_student,
          'graduate_work_chapter_id': graduate_work_chapter_id
        })
        console.log({
          'file': file,
          'graduate_work_chapter_id': graduate_work_chapter_id,
          'graduate_work_student': graduate_work_student
        }, 'set graduate work chapter file comment')
      },

      // updateHandleFileUpload(e) {
      //   let file = e.target.files[0];
      //   let graduate_work_chapter_id = 1
      //   this.SET_GRADUATE_WORK_CHAPTER_UPDATE_FILE({'file': file})
      //   console.log({'file': file}, 'update graduate work chapter file')
      // },
       openAddFinalChapterFileDialog(graduate_work_student) {
        this.addFinalChapterFileDisplay = true
        //this.finalChapterGraduateWorkStudent = graduate_work_student
         this.SET_FINAL_CHAPTER_GRADUATE_WORK_STUDENT(graduate_work_student)
      },
      closeAddFinalChapterFileDialog() {
        this.addFinalChapterFileDisplay = false
        this.CLEAR_STATE_FINAL_CHAPTER()
      },

      async submitGraduateWorkChapterFile() {
        this.isCommentSectionVisible = false
        await this.POST_GRADUATE_WORK_CHAPTER_FILE()
        await this.CLEAR_STATE()
        await this.GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES()
      },
      async submitGraduateWorkFinalChapter() {
        this.isCommentSectionVisible = false

        let res = await this.POST_FINAL_CHAPTER_FILE()
        if (res) {
          this.addFinalChapterFileDisplay = false
          this.$message({text: 'Файл сохранен'})
          await this.CLEAR_STATE_FINAL_CHAPTER()
          await this.GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES()
          await this.GET_CHECK_ACCESS()
          await this.GET_DIPLOMA_RESULTS()
        }

      },
      async submitFinalFileToPlagiat(graduate_work_comment_id) {
        console.log(graduate_work_comment_id, 'submitFinalFileToPlagiat graduate_work_comment_id')

        this.btnSaveStatus = true
        let res = await this.POST_FINAL_FILE_TO_PLAGIAT(graduate_work_comment_id)
        if (res) {
          this.$message({text: 'Отправлено на плагиат'})
          await this.GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES()
          await this.GET_CHECK_ACCESS()
          await this.GET_DIPLOMA_RESULTS()
          this.btnSaveStatus = false
        }
      },

      getFilteredGraduateWorkChapterFiles(graduate_work_chapter_id) {
        return this.uploadGraduateWork_form.studentGraduateWorkChapterFilesInfo.filter(i => i.graduate_work_chapter_id == graduate_work_chapter_id)
      }

    },
    async mounted() {
      await this.GET_STUDENT_GRADUATE_WORK_THEME()
      await this.GET_GRADUATE_WORK_CHAPTER()
      await this.GET_STUDENT_GRADUATE_WORK_CHAPTER_FILES()
      await this.GET_CHECK_ACCESS()
      await this.GET_DIPLOMA_RESULTS()
    }
  }
</script>

<style scoped>

</style>
